export const locale = {
  lang: 'tl',
  data: {
    MENU: {
      HOME: 'Home',
      DASHBOARD: {
        COLLAPSIBLE: 'Dashboards',
        BADGE: '2',
        ANALYTICS: 'Analytics',
        ECOMMERCE: 'eCommerce'
      },
      APPS: {
        SECTION: 'Applications',
        EMAIL: 'Email',
        CHAT: 'Chat',
        TODO: 'Todo',
        CALENDAR: 'Calendar',
        INVOICE: {
          COLLAPSIBLE: 'Invoice',
          LIST: 'List',
          PREVIEW: 'Preview',
          EDIT: 'Edit',
          ADD: 'Add'
        },
        ECOMMERCE: {
          COLLAPSIBLE: 'eCommerce',
          SHOP: 'Shop',
          DETAIL: 'Details',
          WISHLIST: 'Wish List',
          CHECKOUT: 'Checkout'
        },
        USER: {
          COLLAPSIBLE: 'User',
          LIST: 'List',
          VIEW: 'View',
          EDIT: 'Edit'
        }
      },
      
      REPORTS: 'Reports',
      EVENTS: 'Seminars',
      SETTINGS: 'Settings',
      ANNOUNCEMENTS: 'Announcements',
      SETTINGS_LANGUAGE: 'Language',
      SETUP: 'Setup'
    },
    SAMPLE: {
      AWESOME: 'Create Awesome 🙌'
    }
  }
}